import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import openSocket from "socket.io-client";
import $ from "jquery";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        alignItems: "center",
    },
    settingOption: {
        marginLeft: "auto",
    },
    margin: {
        margin: theme.spacing(1),
    },
}));

const BI = () => {
    const classes = useStyles();
    const iframeRef = useRef(null);

    useEffect(() => {
        const socket = openSocket(process.env.REACT_APP_BACKEND_URL);
        return () => {
            socket.disconnect();
        };
    }, []);

    useEffect(() => {
        const updateIframe = () => {
            $(iframeRef.current).attr({
                src: process.env.REACT_APP_BI_URL,
                width: "100%",
                height: "879",
                frameborder: "0",
            });
        };

        updateIframe(); // Atualiza imediatamente

        const interval = setInterval(updateIframe, 900000); // Atualiza a cada 15 minutos

        return () => {
            clearInterval(interval); // Limpa o intervalo ao desmontar o componente
        };
    }, []);

    return (
        <div className={classes.root}>
            <iframe ref={iframeRef}></iframe>
        </div>
    );
};

export default BI;